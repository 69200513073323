<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-01-24 08:54:29
 * @LastEditors: ------
 * @LastEditTime: 2022-02-15 11:27:08
-->
<template>
  <div class="home_container">
    <van-nav-bar title="服务合同管理" safe-area-inset-top placeholder />

    <div class="home_box one" @click="go(0)">
      <span>钟点服务</span>
    </div>

    <div class="home_box two" @click="go(1)">
      <span>短期应急 </span>
      <span>（医院陪护）</span>
    </div>
    <div class="home_box three" @click="go(2)">
      <span>24小时</span>
      <span>生活照护</span>
    </div>
    <div class="home_box fore" @click="go(3)">
      <span>适老无障碍</span>
      <span>设施改造</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    go(num) {
      this.$router.push("/serveDetail?num=" + num);
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  // 这里存放数据
};
</script>
<style lang="less" scoped>
.home_box {
  width: 317px;
  height: 149px;
  border-radius: 8px;
  text-align: center;
  margin: auto;
  margin-top: 30px;
  span {
    display: block;
    width: 50%;
    line-height: 149px;
    font-size: 24px;
    color: #fff;
    margin-left: 6px;
  }
}
.one {
  background: url("../../images/65.png") no-repeat;
  background-size: 100% 100%;
}
.two {
  background: url("../../images/58.png") no-repeat;
  background-size: 100% 100%;
  span {
    height: 40px;
    line-height: 110px;
  }
}
.three {
  background: url("../../images/59.png") no-repeat;
  background-size: 100% 100%;
  span {
    height: 40px;
    line-height: 110px;
  }
}
.fore {
  background: url("../../images/60.png") no-repeat;
  background-size: 100% 100%;
  span {
    height: 40px;
    line-height: 110px;
  }
}
</style>
